/* eslint-disable no-nested-ternary */
import React, { useContext, useMemo } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { country } from "../../utils/i18n";
import { BasketContext } from "../../context/BasketContext";

export default function SubmitButton({ disabled, onClick, data }) {
  const { t } = useTranslation();
  const env = process.env.REACT_APP_NODE_ENV;
  const buttonClassNames = clsx(
    "submit-btn",
    "btn btn--small-wide",
    disabled && "submit-btn--disabled"
  );
  const { basket } = useContext(BasketContext);

  const checkoutURL = useMemo(() =>
    env === "production"
      ? "https://checkout.benebono"
      : env === "staging"
      ? "https://checkout-staging.benebono"
      : "https://checkout-dev.benebono"
  );

  return (
    <form
      action={`${process.env.REACT_APP_CHECKOUT_APP_URL}.${country}`}
      method="POST"
    >
      <input
        hidden
        value={JSON.stringify(basket)}
        name="data"
      />
      <div id="submitButton">
        <input
          type="submit"
          value={t("component.submit_button.value")}
          className={buttonClassNames}
          disabled={disabled}
          onClick={onClick}
        />
      </div>
    </form>
  );
}
