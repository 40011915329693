import React, { useState, useMemo, useEffect, useContext } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import SlotSelection from "../SlotSelection";
import SubmitButton from "../SubmitButton";
import {
  getCookie,
  getIpAddress,
  formatDate,
  getListOfDays,
  daysENToLocale,
  batchSkipper,
} from "../../utils/helpers";
import { baseURL } from "../../constants/baseURL";
import { country } from "../../utils/i18n";
import { BasketContext } from "../../context/BasketContext";

export default function PickupOverview({ pickup, setSelectedPickup }) {
  const { t } = useTranslation();

  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState(null);
  const [isCartUpdated, setIsCartUpdated] = useState(null);
  const [hubID, setHubID] = useState(null);
  const [batches, setBatches] = useState(null);

  useEffect(() => {
    if (!pickup) return;
    // country hard coded at the moment
    const hubIdFetcher = async () => {
      const hubId = await fetch(
        `${baseURL}/delivery/zipcode/${country}/${pickup?.zipcode}`
      );
      const json = await hubId.json();
      setHubID(json.hub_id);
    };
    hubIdFetcher();
  }, []);

  useEffect(() => {
    if (!hubID) return;
    const batchesFetcher = async () => {
      const batchesData = await fetch(`${baseURL}/batches/${hubID}?limit=10`);
      const json = await batchesData.json();
      setBatches(json);
    };

    batchesFetcher();
  }, [hubID]);

  const { setBasket } = useContext(BasketContext);
  const availableSlots = useMemo(
    () =>
      pickup?.slots
        ?.filter((slot) => slot?.available_slots)
        .map((slot) => {
          const indexOfActualDay = daysENToLocale[slot.day].id;
          const actualDay = getListOfDays(indexOfActualDay, 0)[0].value;
          const opens = slot?.opens.split(":")[0];
          const opensMinutes = slot?.opens.split(":")[1];
          const closes = slot?.closes.split(":")[0];
          const closesMinutes = slot?.closes.split(":")[1];

          batchSkipper(batches, actualDay);

          const formattedDate = formatDate(actualDay);

          const finalDateFormat = `${formattedDate.day} ${
            formattedDate.date
          } entre ${opens}h${opensMinutes !== "00" ? opensMinutes : ""} ${
            country === "es" ? "y" : "et"
          }  ${closes}h${closesMinutes !== "00" ? closesMinutes : ""}`;
          // THIS CHECK IS ONLY FOR JEUDI DE L'ASCENSION, TO REMOVE DIMANCHE 22/05

          return {
            label: finalDateFormat,
            value: finalDateFormat,
            deliveryDay: indexOfActualDay,
            firstShippingDate: new Date(actualDay).toISOString(),
            deliveryTimeSlot: `${opens}:${opensMinutes}-${closes}:${closesMinutes}`,
          };
        }),
    [pickup, batches]
  );

  const handleTimeSlotChange = async (event) => {
    const {
      value,
      dataset: { deliveryDay, deliveryTimeSlot, firstShippingDate },
    } = event.target;

    setIsCartUpdated(null);
    setSelectedDeliveryTime(value);

    const _ZapietId = `M=P&L=${pickup?.zapietID}`;
    const _FaitMaisonId = pickup?.faitMaisonID;

    const attributes = {
      "Checkout-Method": "Point retrait",
      "Pickup-Location-Id": pickup?.id,
      "Pickup-Location-Company": pickup?.name,
      "Pickup-Location-Address-Line-1": pickup?.address1,
      "Pickup-Location-Address-Line-2": pickup?.address2,
      "Pickup-Location-City": pickup?.city,
      "Pickup-Location-Postal-Code": pickup?.zipcode,
      "Delivery-Date-&-Time": value,
      anonymousId: JSON.parse(localStorage.getItem("ajs_anonymous_id")),
      fbp: getCookie("_fbp"),
      fbc: getCookie("_fbc"),
      useragent: navigator.userAgent,
      "Delivery-Location-Id": "",
      "Delivery-Date": "",
      "Delivery-Time": "",

      // The followging attributes are used in FaitMaison checkout
      "Delivery-Day": deliveryDay, // 0-6 (sunday - saturday)
      "First-Shipping-Date": firstShippingDate, // 2021-12-20T00:00:00Z
      "Delivery-Time-Slot": deliveryTimeSlot, // 18:00-20:00
    };

    setBasket(
      (prevState) => ({
        ...prevState,
        attributes,
        _ZapietId,
        _FaitMaisonId: pickup?.faitMaisonID,
      }),
      setIsCartUpdated(true)
    );
  };

  return (
    <div className="pu-overview">
      <div className="pu-overview__header">
        <ArrowBackIosIcon
          onClick={() => setSelectedPickup(null)}
          className="pu-overview__header__icon"
        />
        <p className="pu-overview__header__text">
          {t("component.pickup_overview.header_text")}
        </p>
      </div>
      <div className="pu-overview__main">
        <p className="pu-overview__name">{pickup?.name}</p>
        <p className="pu-overview__address">
          {pickup?.address1}, {pickup?.zipcode} {pickup?.city}{" "}
        </p>
        <p className="pu-overview__title">
          {t("component.pickup_overview.title")}
        </p>
        <div className="pu-overview__slots">
          <SlotSelection
            value={selectedDeliveryTime}
            onChange={handleTimeSlotChange}
            availableSlots={availableSlots}
          />
        </div>
        <div className="pu-overview__submit-btn">
          <SubmitButton
            disabled={!isCartUpdated || !selectedDeliveryTime}
            onClick={() => {
              const userZipCode = localStorage.getItem("zipCode");
              //  Analytics
              window.rudderanalytics.track("Checkout Step Completed", {
                postal_code: userZipCode,
                step: "2",
              });
            }}
            data={isCartUpdated}
          />
        </div>
      </div>
    </div>
  );
}
