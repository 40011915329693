import { createContext, useState, useMemo, useEffect } from "react";

const BasketContext = createContext();

const BasketProvider = ({ children }) => {
  const [basket, setBasket] = useState(null);

  // mock to test in local
  // const basketData = {
  //   id: "18",
  //   name: "Format Essentiel / 100% légumes",
  //   price: 1190,
  //   quantity: 1,
  //   imageUrl:
  //     "https://benebono-strapi-uploads.s3.eu-west-3.amazonaws.com/Essential_100_veggies_SS_FR_copie_4190b64672.jpg",
  //   imageAlt: "Panier Bene Bono",
  //   variantId: 42162520883456,
  //   country: "fr",
  //   promotionCode: null,
  // };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const basketData = JSON.parse(urlParams.get("basket"));
    setBasket(basketData);
  }, []);

  useEffect(() => {
    if (window.posthog && basket) {
      // eslint-disable-next-line no-undef
      posthog.capture("Debug Cart Content", {
        step: "Store Locator",
        basket,
      });
    }
  }, [basket]);

  const contextValue = useMemo(
    () => ({
      basket,
      setBasket,
    }),
    [basket]
  );

  return (
    <BasketContext.Provider value={contextValue}>
      {children}
    </BasketContext.Provider>
  );
};

export { BasketContext, BasketProvider };
