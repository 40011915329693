/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";
import ToggleShippingMethod from "../ToggleShippingMethod/ToggleShippingMethod";

export default function MapsHeader({
  shippingMethod,
  setShippingMethod,
  setActiveStep,
  address,
  setActivePickup,
}) {
  const navigate = useNavigate();

  return (
    <div id="maps-header">
      <div className="maps-header">
        <img
          onClick={() => (window.location.href = "/")}
          className="js lazyautosizes lazyloaded maps-header__logo"
          src="https://cdn.shopify.com/s/files/1/0274/8596/2317/t/92/assets/logo-header-benebono-mobile.png?v=7155136542372977251669813969"
          data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
          data-aspectratio="6.0814332247557"
          data-sizes="auto"
          alt="Bene Bono"
          style={{ maxWidth: "250px" }}
          sizes="250px"
        />
        <div
          className="maps-header__address-container"
          onClick={() => {
            setActiveStep("addressSelection");
            setActivePickup(null);
            navigate({
              pathname: "/",
              search: `?activeStep=addressSelection`,
            });
          }}
        >
          <span className="maps-header__address">{address}</span>
          <CreateIcon className="maps-header__icon" />
        </div>
        <hr className="maps-header__divider" />
        <div className="maps-header__toggle">
          <ToggleShippingMethod
            shippingMethod={shippingMethod}
            setShippingMethod={setShippingMethod}
          />
        </div>
      </div>
    </div>
  );
}
