import React, { useEffect, useState, useMemo, useContext } from "react";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import SubmitButton from "../SubmitButton";
import SlotSelection from "../SlotSelection";
import {
  formatDate,
  getListOfDays,
  daysENToLocale,
  getCookie,
  getIpAddress,
  batchSkipper,
} from "../../utils/helpers";
import { baseURL } from "../../constants/baseURL";
import { country } from "../../utils/i18n";

import { BasketContext } from "../../context/BasketContext";

export default function PartnerDelivery() {
  const { t } = useTranslation();

  const [partners, setPartners] = useState([]);
  const [selectedPartnerPickups, setSelectedPartnerPickups] = useState(null);
  const [activePartner, setActivePartner] = useState(null);
  const [activePickup, setActivePickup] = useState(null);
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState(null);
  const [isCartUpdated, setIsCartUpdated] = useState(null);
  const [partnersLoading, setPartnersLoading] = useState(false);
  const [pickupsLoading, setPickupsLoading] = useState(false);
  const [batches, setBatches] = useState(null);
  const [hubID, setHubID] = useState(null);

  useEffect(() => {
    if (!activePickup) return;
    const hubIdFetcher = async () => {
      const hubId = await fetch(
        `${baseURL}/delivery/zipcode/${country}/${activePickup?.zipcode}`
      );
      const json = await hubId.json();
      setHubID(json.hub_id);
    };
    hubIdFetcher();
  }, [activePickup]);

  useEffect(() => {
    if (!hubID) return;
    const batchesFetcher = async () => {
      const batchesData = await fetch(`${baseURL}/batches/${hubID}?limit=10`);
      const json = await batchesData.json();
      setBatches(json);
    };

    batchesFetcher();
  }, [hubID]);
  const { setBasket } = useContext(BasketContext);

  useEffect(() => {
    setPartnersLoading(true);
    fetch(`${baseURL}/partnerships`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          setPartners(data?.data);
          setPartnersLoading(false);
        }
      })
      .catch((e) => setPartnersLoading(false));
  }, []);

  const handlePartnerClick = (partner) => {
    setPickupsLoading(true);
    setSelectedDeliveryTime(null);
    setActivePickup(null);
    setActivePartner(partner);
    fetch(`${baseURL}/partnerships/${partner.ID}/pickups`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          setSelectedPartnerPickups(data?.data);
          setPickupsLoading(false);
        }
      })
      .catch(() => setPickupsLoading(false));
  };

  const handlePickupClick = (pickup) => {
    setSelectedDeliveryTime(null);
    const openingHours = pickup?.opening_hours;
    const slots = [];
    Object.keys(openingHours).forEach((day, index) => {
      if (openingHours[day]?.available_slots > 0) {
        slots.push({
          day,
          ...openingHours[day],
        });
      }
    });
    const sortedSlots = slots.sort(
      (slotA, slotB) =>
        daysENToLocale[slotA.day].id - daysENToLocale[slotB.day].id
    );

    setActivePickup({ ...pickup, slots: sortedSlots });
  };

  const handleTimeSlotChange = async (event) => {
    const {
      value,
      dataset: { deliveryDay, deliveryTimeSlot, firstShippingDate },
    } = event.target;
    setIsCartUpdated(false);
    setSelectedDeliveryTime(value);

    const _ZapietId = `M=P&L=${activePickup?.zapietID}`;
    const _FaitMaisonId = activePickup?.faitMaisonID;

    const attributes = {
      "Checkout-Method": "Point retrait partenaire",
      "Pickup-Location-Id": activePickup.zapietID,
      "Pickup-Location-Company": activePickup.name,
      "Pickup-Location-Address-Line-1": activePickup.address1,
      "Pickup-Location-Address-Line-2": activePickup.address2,
      "Pickup-Location-City": activePickup.city,
      "Pickup-Location-Postal-Code": activePickup.zipcode,
      "Delivery-Date-&-Time": value,
      anonymousId: JSON.parse(localStorage.getItem("ajs_anonymous_id")),
      fbp: getCookie("_fbp"),
      fbc: getCookie("_fbc"),
      useragent: navigator.userAgent,
      "Delivery-Location-Id": "",
      "Delivery-Date": "",
      "Delivery-Time": "",

      // The followging attributes are used in FaitMaison checkout
      "Delivery-Day": deliveryDay, // 0-6 (sunday - saturday)
      "First-Shipping-Date": firstShippingDate, // 2021-12-20T00:00:00Z
      "Delivery-Time-Slot": deliveryTimeSlot, // 18:00-20:00
    };
    setBasket(
      (prevState) => ({
        ...prevState,
        attributes,
        _ZapietId,
        _FaitMaisonId: activePickup?.faitMaisonID,
      }),
      setIsCartUpdated(true)
    );
  };

  const availableSlots = useMemo(
    () =>
      activePickup?.slots.map((slot) => {
        const indexOfActualDay = daysENToLocale[slot.day].id;
        const actualDay = getListOfDays(indexOfActualDay, 0)[0].value;
        const opens = slot?.opens.split(":")[0];
        const opensMinutes = slot?.opens.split(":")[1];
        const closes = slot?.closes.split(":")[0];
        const closesMinutes = slot?.closes.split(":")[1];

        batchSkipper(batches, actualDay);
        const formattedDate = formatDate(actualDay);
        const finalDateFormat = `${formattedDate.day} ${
          formattedDate.date
        } entre ${opens}h${
          opensMinutes !== "00" ? opensMinutes : ""
        } et ${closes}h${closesMinutes !== "00" ? closesMinutes : ""}`;

        return {
          label: finalDateFormat,
          value: finalDateFormat,
          deliveryDay: indexOfActualDay,
          firstShippingDate: new Date(actualDay).toISOString(),
          deliveryTimeSlot: `${opens}:${opensMinutes}-${closes}:${closesMinutes}`,
        };
      }),
    [activePickup, batches]
  );

  const PartnerSelection = () =>
    partners.map((partner) => {
      const { ID, name } = partner;
      const partnerNameClasses = clsx(
        "name",
        activePartner?.ID === ID && "name--active"
      );
      return (
        <div className="item" onClick={() => handlePartnerClick(partner)}>
          <p className={partnerNameClasses}>{name}</p>
          <hr className="divider" />
        </div>
      );
    });

  const PartnerPickupSelection = () =>
    selectedPartnerPickups.map((pickup, index) => {
      const { name, address1, city, zipcode } = pickup;
      const pickupNameClasses = clsx(
        "name",
        activePickup?.index === index && "name--active"
      );
      return (
        <div
          className="item"
          onClick={() => handlePickupClick({ ...pickup, index })}
        >
          <p className={pickupNameClasses}>{name}</p>
          <p className="address">
            {address1} , {zipcode} {city}
          </p>
          <hr className="divider" />
        </div>
      );
    });

  return (
    <div className="partner">
      {partnersLoading && (
        <div className="loader">
          <CircularProgress color="inherit" />
        </div>
      )}
      <h1 className="partner__header">
        {t("component.partner_delivery.partners_loading_header")}
      </h1>

      {partners && <PartnerSelection />}

      {pickupsLoading ? (
        <div className="loader">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        selectedPartnerPickups && (
          <div className="partner__pickups">
            <h1 className="partner__header">
              {t("component.partner_delivery.pickups_loading_header_1")}
              {country === "fr" && selectedPartnerPickups.length > 1
                ? "s"
                : ""}{" "}
              {t("component.partner_delivery.pickups_loading_header_2")}
              {country === "fr" && selectedPartnerPickups.length > 1
                ? "s"
                : ""}{" "}
              {t("component.partner_delivery.pickups_loading_header_3")}
              {activePartner.name} :
            </h1>
            {selectedPartnerPickups.length ? (
              <PartnerPickupSelection />
            ) : (
              <p>
                {t("component.partner_delivery.no_withdrawal_point_available")}
              </p>
            )}
          </div>
        )
      )}

      {activePickup && (
        <div className="partner__pickups">
          <h1 className="partner__header">
            {t("component.partner_delivery.active_pickup_header")}{" "}
          </h1>
          <SlotSelection
            value={selectedDeliveryTime}
            onChange={handleTimeSlotChange}
            availableSlots={availableSlots}
          />
        </div>
      )}

      <SubmitButton
        disabled={!isCartUpdated || !selectedDeliveryTime}
        onClick={() => {
          // const userZipCode = activePickup?.zipcode;
          // //  Analytics
          // window.rudderanalytics.track("Checkout Step Completed", {
          //   postal_code: userZipCode,
          //   step: "2",
          // });
        }}
        data={isCartUpdated}
      />
    </div>
  );
}
