import React from "react";
import AddressSugestionIcon from "../svg/AddressSuggestionIcon";

/**
 * AddressRow component
 */

const AddressRow = ({ suggestion, getSuggestionItemProps }) => {
  const {
    formattedSuggestion: { mainText, secondaryText },
    active,
  } = suggestion;
  const className = active
    ? "suggestion-item suggestion-item--active"
    : "suggestion-item";
  return (
    <div
      {...getSuggestionItemProps(suggestion, {
        className,
      })}
    >
      <AddressSugestionIcon />
      <div className="suggestion-item__text">
        <span className="main">{mainText}</span>
        <span className="secondary">{secondaryText}</span>
      </div>
    </div>
  );
};

export default function AddressSuggestions({
  suggestions,
  getSuggestionItemProps,
}) {
  return (
    <div className="autocomplete-dropdown-container">
      {suggestions.map((suggestion) => (
        <AddressRow
          suggestion={suggestion}
          getSuggestionItemProps={getSuggestionItemProps}
        />
      ))}
    </div>
  );
}
