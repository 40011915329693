import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import React, { useEffect } from "react";
import { Routes, Route, HashRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LocationPickerRoot from "./components/LocationPickerRoot";
import "./styles/_GlobalStyles.scss";
import { BasketProvider } from "./context/BasketContext";
import configureI18n, { country } from "./utils/i18n";

const theme = createTheme({
  typography: {
    fontFamily: `"Poppins", "sans-serif"`,
  },
});

Sentry.init({
  dsn: "https://edd71ae603c24ae4adde52ef1069ddb0@o1238952.ingest.sentry.io/4505006060404736",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const StoreLocatorApp = () => {
  useEffect(() => {
    window.location.replace("/");
  }, []);

  return null;
};
configureI18n();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <BasketProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route exact path="/" element={<LocationPickerRoot />} />
            <Route
              exact
              path="/store-locator-app"
              element={<StoreLocatorApp />}
            />
          </Routes>
        </ThemeProvider>
      </BasketProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
