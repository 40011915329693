/* eslint-disable dot-notation */
import React, { useState, useContext, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import AddressSelection from "../AddressSelection";
import MapsRoot from "../MapsRoot";
import PartnerDelivery from "../PartnerDelivery/PartnerDelivery";
import { getURLParams, useExternalScript } from "../../utils/helpers";
import { BasketContext } from "../../context/BasketContext";
import { country } from "../../utils/i18n";

const LocationPickerRoot = () => {
  const location = useLocation();
  const urlParamsJSON = getURLParams(location);

  const [latLng, setLatLng] = useState({
    lat: parseFloat(urlParamsJSON["lat"]),
    lng: parseFloat(urlParamsJSON["lng"]),
  });

  const [activeStep, setActiveStep] = useState(() => {
    const stepFromUrl = urlParamsJSON["activeStep"];
    if (stepFromUrl) {
      if (
        stepFromUrl === "addressSelection" ||
        stepFromUrl === "deliveryMethod" ||
        stepFromUrl === "partners"
      ) {
        return stepFromUrl;
      }
      return "addressSelection";
    }
    return "addressSelection";
  });
  useEffect(() => {
    const stepFromUrl = urlParamsJSON["activeStep"];
    if (stepFromUrl) {
      setActiveStep(urlParamsJSON["activeStep"]);
    }
    setLatLng({
      lat: parseFloat(urlParamsJSON["lat"]),
      lng: parseFloat(urlParamsJSON["lng"]),
    });
  }, [location]);
  const [address, setAddress] = useState(
    urlParamsJSON["address"] ? urlParamsJSON["address"] : ""
  );

  const googleScript = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBYTW7k2L6zxpNe22aHMybhmkYZXgMsFVc&libraries=places&language=${country.toUpperCase()}`;
  const googleStatus = useExternalScript(googleScript);

  return (
    <>
      {activeStep === "addressSelection" && googleStatus === "ready" && (
        <AddressSelection
          address={address}
          setAddress={setAddress}
          setActiveStep={setActiveStep}
          setLatLng={setLatLng}
        />
      )}
      {activeStep === "deliveryMethod" && googleStatus === "ready" && (
        <MapsRoot
          setActiveStep={setActiveStep}
          address={address}
          latLng={latLng}
        />
      )}
      {activeStep === "partners" && googleStatus === "ready" && (
        <PartnerDelivery />
      )}
    </>
  );
};

export default LocationPickerRoot;
