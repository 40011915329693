/* eslint-disable import/prefer-default-export */
// const sw = window.google && window.google.maps.LatLng(48.815573, 2.224199);
// const ne = window.google && window.google.maps.LatLng(48.9021449, 2.4699208);
// export const shippingBounderies =
//   window.google && window.google.maps.LatLngBounds(sw, ne);

import { country } from "../utils/i18n";

let center;
let bounds;

switch (country) {
  case "fr":
    center = { lat: 47.0342, lng: 3.1745 };
    bounds = {
      north: center.lat + 2,
      south: center.lat - 2,
      east: center.lng + 1,
      west: center.lng - 1,
    };
    break;
  case "es":
    center = { lat: 40.4637, lng: -3.7492 };
    bounds = {
      north: center.lat + 2,
      south: center.lat - 2,
      east: center.lng + 1,
      west: center.lng - 1,
    };
    break;
  default:
    break;
}

export const printPrice = (
  amountX100 = 0,
  currentSymbol = "€",
  precision = 2
) => {
  const formatPrice = `${(amountX100 / 100).toFixed(precision)}`;
  const [euros, cents] = formatPrice.split(".");
  if (cents === "00") return `${euros}${currentSymbol}`;
  return `${formatPrice}${currentSymbol}`;
};

export const defaultBounds = bounds;
