/* eslint-disable array-callback-return */
/* eslint-disable dot-notation */
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import clsx from "clsx";

import {
  getZipcodeWithreverseGeocodeByAddress,
  getURLParams,
  getZipcodeInfo,
} from "../../utils/helpers";
import { country } from "../../utils/i18n";
import { printPrice } from "../../constants/utils";

export default function ToggleShippingMethod({
  shippingMethod,
  setShippingMethod,
}) {
  const { t } = useTranslation();

  const handleChange = (newShippingMethod) => {
    setShippingMethod(newShippingMethod);
  };
  const location = useNavigate();
  const [isZoneAvailable, setIsZoneAvailable] = useState(true);
  const [zipCodeInfo, setZipCodeInfo] = useState();

  useEffect(() => {
    const checkDeliveryAvailability = async () => {
      const userZipCode = localStorage.getItem("zipCode");
      if (userZipCode) {
        const zipcodeDetails = await getZipcodeInfo(userZipCode);
        if (!zipcodeDetails) {
          setIsZoneAvailable(false);
        } else {
          const isDeliveryAvailable = zipcodeDetails?.home_delivery?.find(
            (daySlot) => daySlot?.length
          );
          if (!isDeliveryAvailable) {
            setIsZoneAvailable(false);
          }
        }
      } else {
        const urlParamsJSON = getURLParams(location);
        const coordinates = {
          lat: urlParamsJSON["lat"]
            ? parseFloat(urlParamsJSON["lat"])
            : 48.8566,
          lng: urlParamsJSON["lng"] ? parseFloat(urlParamsJSON["lng"]) : 2.3522,
        };

        getZipcodeWithreverseGeocodeByAddress(coordinates).then(
          async (zipcode) => {
            const zipcodeInfo = await getZipcodeInfo(zipcode);

            if (!zipcodeInfo) {
              setIsZoneAvailable(false);
            } else {
              const isDeliveryAvailable = zipcodeInfo?.home_delivery?.find(
                (daySlot) => daySlot?.length
              );
              if (!isDeliveryAvailable) {
                setIsZoneAvailable(false);
              }
            }
          }
        );
      }
    };

    checkDeliveryAvailability();
  }, []);

  useEffect(() => {
    const userZipCode = localStorage.getItem("zipCode");
    const zipCodeShippingCost = async () => {
      const data = await getZipcodeInfo(userZipCode);
      setZipCodeInfo(data);
    };
    zipCodeShippingCost();
  }, []);

  const shippingCost = `+${printPrice(zipCodeInfo?.shippingCost)}`;

  return (
    <div className="toggle-method">
      {[
        {
          key: "pr",
          title: t("component.shipping_method_pr"),
          icon: <ShoppingCartIcon />,
        },
        {
          key: "lad",
          title: (
            <Trans
              i18nKey="component.shipping_method_lad"
              values={{ shippingCost }}
            />
          ),
          icon: <LocalShippingIcon />,
        },
      ].map((method, index) => {
        const buttonClasses = clsx(
          "toggle-method__btn",
          shippingMethod === method.key && "toggle-method__btn--active"
        );
        const ladNotAvailable = method.key === "lad" && !isZoneAvailable;

        const disabled = ladNotAvailable;
        return (
          <button
            type="button"
            className={buttonClasses}
            disabled={disabled}
            onClick={() => {
              handleChange(method.key);
              localStorage.setItem("shippingMethod", method.key);
            }}
          >
            <div className="method-header">
              <span className="method-icon">{method.icon}</span>
              <span className="method-title">{method.title}</span>
            </div>
            {disabled && (
              <small>{t("component.toggle_shipping_method.button")}</small>
            )}
          </button>
        );
      })}
    </div>
  );
}
