// Fundamentals
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { bucketURL } from "../constants/baseURL";

const TRANSLATION_BUCKET_URL = bucketURL;

const FR_FR_KEY = "fr-FR";
const ES_ES_KEY = "es-ES";

const topLevelDomain = window.location.hostname.split(".").pop();
export const country = topLevelDomain === "es" ? "es" : "fr";

export default function configureI18n() {
  let defaultLocale;

  switch (country) {
    case "es":
      defaultLocale = ES_ES_KEY;
      break;

    default:
      defaultLocale = FR_FR_KEY;
      break;
  }

  const availableLanguages = [FR_FR_KEY, ES_ES_KEY];

  return (
    i18n
      // Load translation via HTTP, uses https://github.com/i18next/i18next-http-backends
      .use(HttpApi)
      // Detect language from user-agent, uses: https://github.com/i18next/i18next-browser-languageDetector
      // Uncomment this line when the ES translations are ready
      // .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        keySeparator: false,
        fallbackLng: defaultLocale,
        nonExplicitSupportedLngs: true,
        react: {
          useSuspense: false,
          transKeepBasicHtmlNodesFor: ["br", "strong", "span", "p"],
        },
        interpolation: {
          escapeValue: false,
        },
        backend: {
          allowMultiLoading: false,
          loadPath: (lng) => {
            const key = lng.join("-");
            if (availableLanguages.includes(key)) {
              return [TRANSLATION_BUCKET_URL, `${key}.json`].join("/");
            }

            return null;
          },
        },
      })
  );
}
