import React from "react";

export default function Marker({
  text,
  id,
  activePickup,
  setActivePickup,
  setSelectedPickup,
  displayMarkers,
}) {
  return (
    <div
      className="any-pin"
      style={{
        background: id === activePickup && "#fc7033",
        opacity: displayMarkers && "1",
      }}
      onClick={() => {
        setSelectedPickup(null);
        setActivePickup(id);
      }}
    >
      <div
        style={{
          transform: "translate(-50%, -50%) rotate(45deg)",
          position: "absolute",
          left: "50%",
          top: "50%",
        }}
      >
        {text}
      </div>
    </div>
  );
}
