/* eslint-disable no-sequences */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */

import { useEffect, useState } from "react";
import { baseURL } from "../constants/baseURL";
import { country } from "./i18n";

/* eslint-disable no-plusplus */
export const zapietLocationLad = "146632";
export const googleApiKey = "AIzaSyBYTW7k2L6zxpNe22aHMybhmkYZXgMsFVc";

export const daysENToLocale = {
  sunday: {
    id: 0,
    fr: "Dimanche",
    es: "Domingo",
  },
  monday: {
    id: 1,
    fr: "Lundi",
    es: "Lunes",
  },
  tuesday: { id: 2, fr: "Mardi", es: "Martes" },
  wednesday: { id: 3, fr: "Mercredi", es: "Miércoles" },
  thursday: { id: 4, fr: "Jeudi", es: "Jueves" },
  friday: { id: 5, fr: "Vendredi", es: "Viernes" },
  saturday: { id: 6, fr: "Samedi", es: "Sábado" },
};

const daysLocale = {
  fr: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split("_"),
  es: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
};

const monthsLocale = {
  fr: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
    "_"
  ),
  es: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split(
    "_"
  ),
};

export const availableSlots = [
  {
    label: "Entre 12h et 14h",
    value: "Entre 12h et 14h",
    deliveryTimeSlot: "12:00-14:00",
  },
  {
    label: "Entre 14h et 16h",
    value: "Entre 14h et 16h",
    deliveryTimeSlot: "14:00-16:00",
  },
  {
    label: "Entre 16h et 18h",
    value: "Entre 16h et 18h",
    deliveryTimeSlot: "16:00-18:00",
  },
  {
    label: "Entre 18h et 20h",
    value: "Entre 18h et 20h",
    deliveryTimeSlot: "18:00-20:00",
  },
  {
    label: "Entre 20h et 22h",
    value: "Entre 20h et 22h",
    deliveryTimeSlot: "20:00-22:00",
  },
];

export const formatAvailableSlots = (slot) => {
  if (!slot) {
    return {
      label: "",
      value: "",
      deliveryTimeSlot: "",
    };
  }

  const [from, to] = slot.split("-");
  const [fromHH] = from.split(":");
  const [toHH] = to.split(":");

  const formattedSlot = `Entre ${fromHH}h ${
    country === "es" ? "y" : "et"
  } ${toHH}h`;

  return {
    label: formattedSlot,
    value: formattedSlot,
    deliveryTimeSlot: slot,
  };
};

export const formatDate = (dateToFormat) => {
  // date format = new Date()
  if (typeof dateToFormat === "object") {
    const day = daysLocale[country][dateToFormat.getDay()];
    const month = monthsLocale[country][dateToFormat.getMonth()];
    const date = dateToFormat.getDate();
    return { day, date: `${date} ${month}` };
  }
};

const getDaysFromTo = (nextMonday, from, numberOfDays) => {
  // from format: new Date()
  // numberOfDays format: integer
  // if (typeof from === "number" && typeof numberOfDays === "number") {
  const days = [
    {
      id: 0,
      dayOfWeek: from,
      value: new Date(
        new Date(nextMonday).setDate(nextMonday.getDate() + from - 1)
      ),
    },
  ];
  if (numberOfDays) {
    for (let i = 1; i < numberOfDays; i++) {
      const nextDay = new Date(
        new Date(nextMonday).setDate(nextMonday.getDate() + from - 1 + i)
      );
      days.push({ id: i, dayOfWeek: from + i, value: nextDay });
    }
  }
  return days;
  // }
};

export const getListOfDays = (from, numberOfDays) => {
  const today = new Date();
  let mondayOfNextWeek;
  const todayOfWeek = today.getDay(); // Sunday - Saturday : 0 - 6
  switch (todayOfWeek) {
    case 0: // Sunday
      const nextMonday = new Date(today.setDate(today.getDate() + 8));
      return getDaysFromTo(nextMonday, from, numberOfDays);
    case 1: // Monday
      mondayOfNextWeek = new Date(today.setDate(today.getDate() + 7));
      return getDaysFromTo(mondayOfNextWeek, from, numberOfDays);
    case 2: // Tuesday
      mondayOfNextWeek = new Date(today.setDate(today.getDate() + 6));

      return getDaysFromTo(mondayOfNextWeek, from, numberOfDays);
    case 3: // Wednesday
      mondayOfNextWeek = new Date(today.setDate(today.getDate() + 5));
      return getDaysFromTo(mondayOfNextWeek, from, numberOfDays);
    case 4: // Thursday
      mondayOfNextWeek = new Date(today.setDate(today.getDate() + 4));
      return getDaysFromTo(mondayOfNextWeek, from, numberOfDays);
    case 5: // Friday
      mondayOfNextWeek = new Date(today.setDate(today.getDate() + 3));
      return getDaysFromTo(mondayOfNextWeek, from, numberOfDays);
    case 6: // Saturday
      mondayOfNextWeek = new Date(today.setDate(today.getDate() + 2));
      return getDaysFromTo(mondayOfNextWeek, from, numberOfDays);
    default:
      break;
  }
};

export function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d; // distance returned
}

export function getCookie(cookie) {
  if (
    document.cookie.split(";").some((item) => item.trim().startsWith(cookie))
  ) {
    const value = document.cookie
      .split("; ")
      .find((row) => row.startsWith(cookie))
      .split("=")[1];
    return value;
  }
  return undefined;
}

export function getIpAddress() {
  let ip = null;
  $.ajax({
    url: "https://www.cloudflare.com/cdn-cgi/trace",
    type: "get",
    dataType: "html",
    async: false,
    success(data) {
      data = data
        .trim()
        .split("\n")
        .reduce((obj, pair) => {
          pair = pair.split("=");
          return (obj[pair[0]] = pair[1]), obj;
        }, {});
      ip = data?.ip;
    },
  });
  return ip;
}

export const getZipcodeWithreverseGeocodeByAddress = async ({ lat, lng }) => {
  let zipcode;

  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleApiKey}`
  );

  const reversedAddress = await res.json();

  if (reversedAddress && reversedAddress.results?.length) {
    const { results } = reversedAddress;

    const filteredMap = results.filter((result) =>
      result.types.includes("postal_code")
    );

    if (filteredMap.length === 0) {
      for (let i = 0; i < results.length; i++) {
        for (let j = 0; j < results[i].address_components.length; j++) {
          for (
            let k = 0;
            k < results[i].address_components[j].types.length;
            k++
          ) {
            if (results[i].address_components[j].types[k] === "postal_code") {
              zipcode =
                results[i].address_components[j].long_name ||
                results[i].address_components[j].short_name;
              return zipcode;
            }
          }
        }
      }
    } else {
      const filteredZipcode = filteredMap[0].address_components.filter(
        (address) => address.types.includes("postal_code")
      );

      zipcode = filteredZipcode[0].long_name ?? filteredZipcode[0].long_name;

      return zipcode;
    }
  }

  return zipcode;
};

export const getURLParams = (location) => {
  const [, query] = location.search.split("?");
  const urlParams = new URLSearchParams(query);
  const urlEntries = Array.from(urlParams.entries());
  return urlEntries.reduce(
    (all, [key, value]) => ({
      ...all,
      [key]: urlParams.get(key),
    }),
    {}
  );
};

export async function getZipcodeInfo(zipcode) {
  try {
    const response = await fetch(
      `${baseURL}/delivery/zipcode/${country}/${zipcode}`
    );
    if (response.status === 200) {
      const res = await response.json();
      return res;
    }
    return false;
  } catch (zipcodeError) {
    return false;
  }
}

export const gapChecker = (prevDate, currDate) => {
  const difference =
    new Date(currDate).getTime() - new Date(prevDate).getTime();
  return Math.ceil(difference / (1000 * 3600 * 24)) === 7;
};

export const batchSkipper = (batches, date) => {
  const removedBatches = batches?.filter((batch) => batch.removed);
  const shouldSkip = batches?.[1].removed;
  let skipTimes = 1;
  if (removedBatches && shouldSkip) {
    removedBatches.forEach((removedBatch, index, removedBatchesArray) => {
      if (
        removedBatchesArray[index - 1] &&
        gapChecker(
          removedBatchesArray[index - 1].started_at,
          removedBatch.started_at
        )
      )
        skipTimes += 1;
    });
    date.setDate(date.getDate() + 7 * skipTimes);
  }
};

export const useExternalScript = (url) => {
  const [state, setState] = useState(url ? "loading" : "idle");

  useEffect(() => {
    if (!url) {
      setState("idle");
      return;
    }

    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e) => {
      setState(e.type === "load" ? "ready" : "error");
    };

    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);
    }

    script.addEventListener("load", handleScript);
    script.addEventListener("error", handleScript);

    return () => {
      script.removeEventListener("load", handleScript);
      script.removeEventListener("error", handleScript);
    };
  }, [url]);

  return state;
};
