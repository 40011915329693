/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useEffect, useContext, useState } from "react";
import clsx from "clsx";
import { BasketContext } from "../../context/BasketContext";
import {
  formatDate,
  zapietLocationLad,
  getIpAddress,
  getCookie,
  batchSkipper,
} from "../../utils/helpers";
import { baseURL } from "../../constants/baseURL";

export default function DaySelection({
  selectedDeliveryDay,
  setSelectedDeliveryDay,
  setIsCartUpdated,
  days,
  userZipcode,
}) {
  const containerRef = useRef(null);
  const [batches, setBatches] = useState(null);
  const [validSlots, setValidSlots] = useState([]);

  useEffect(() => {
    if (!userZipcode?.hub_id) return;
    const batchesFetcher = async () => {
      const batchesData = await fetch(
        `${baseURL}/batches/${userZipcode.hub_id}?limit=10`
      );
      const json = await batchesData.json();
      setBatches(json);
    };

    batchesFetcher();
  }, [userZipcode?.hub_id]);

  useEffect(() => {
    if (userZipcode?.home_delivery && batches) {
      const vSlots = userZipcode?.home_delivery.map((timeFrame, index) => {
        if (timeFrame.length) {
          const activeDate = days.find((day) => day.dayOfWeek === index);
          if (activeDate) {
            const date = activeDate?.value;
            const tmpDate = new Date(date);
            batchSkipper(batches, tmpDate);
            return {
              id: index,
              value: formatDate(tmpDate),
              deliveryDay: tmpDate.getDay(),
              firstShippingDate: tmpDate.toISOString(),
            };
          }
        }
      });
      setValidSlots(vSlots);
    }
  }, [userZipcode?.home_delivery, batches]);
  const { setBasket } = useContext(BasketContext);

  useEffect(() => {
    if (userZipcode) {
      const index = userZipcode?.home_delivery.findIndex(
        (daySlots) => daySlots?.length
      );

      const activeDate = days.find((day) => day.dayOfWeek === index);
      if (activeDate) {
        const date = activeDate?.value;
        const tmpDate = new Date(date);

        batchSkipper(batches, tmpDate);
        setSelectedDeliveryDay({
          id: index,
          value: formatDate(tmpDate),
          deliveryDay: tmpDate.getDay(),
          firstShippingDate: tmpDate.toISOString(),
        });
      }
    }
  }, [userZipcode, batches]);

  const handleDaySlotChange = async (dayBox) => {
    const { id, day, date, deliveryDay, firstShippingDate } = dayBox;

    setSelectedDeliveryDay({
      id,
      value: { day, date },
      deliveryDay,
      firstShippingDate,
    });
    setIsCartUpdated(null);
    const _ZapietId = `M=D&L=${zapietLocationLad}`;
    const attributes = {
      "Delivery-Date": `${day} ${date}`,
      anonymousId: JSON.parse(localStorage.getItem("ajs_anonymous_id")),
      fbp: getCookie("_fbp"),
      fbc: getCookie("_fbc"),
      useragent: navigator.userAgent,
      "Pickup-Location-Id": "",
      "Pickup-Location-Company": "",
      "Pickup-Location-Address-Line-1": "",
      "Pickup-Location-Address-Line-2": "",
      "Pickup-Location-City": "",
      "Pickup-Location-Region": "",
      "Pickup-Location-Postal-Code": userZipcode?.zipcode,
      "Pickup-Location-Country": "",
      "Delivery-Date-&-Time": "",

      // The followging attributes are used in FaitMaison checkout
      "Delivery-Day": deliveryDay, // 0-6 (sunday - saturday)
      "First-Shipping-Date": firstShippingDate, // 2021-12-20T00:00:00Z
      // "Delivery-Time-Slot": deliveryTimeSlot, // 18:00-20:00
    };

    setBasket(
      (prevState) => ({
        ...prevState,
        attributes,
        _ZapietId,
        _FaitMaisonId: "",
      }),
      setIsCartUpdated(true)
    );
  };

  const DayBox = () => (
    <div
      id="day-box__container"
      className="day-box__container"
      ref={containerRef}
    >
      {days.map((boxDay, i) => {
        const { id, dayOfWeek, value } = boxDay;
        const timesSlots = userZipcode?.home_delivery[i]?.map(
          (timeslot) => timeslot
        );

        console.log({ timesSlots });

        // isZoneNotAvailableAllWeek is true for zones which are available only in certain days for LAD
        // if its true, we want to disable tuesday (id = 0) and thursday (id = 2)
        if (userZipcode?.home_delivery?.[dayOfWeek]?.length) {
          const className = clsx(
            "day-box__item",
            selectedDeliveryDay?.id === dayOfWeek && "day-box__item--active"
          );

          const { day, date } = formatDate(
            new Date(validSlots[i]?.firstShippingDate)
          );

          return (
            <div
              id={`day-box__item-${id}`}
              className={className}
              onClick={() => {
                const dayBox = {
                  id: dayOfWeek,
                  day,
                  date,
                  deliveryDay: new Date(value).getDay(),
                  firstShippingDate: new Date(value).toISOString(),
                };
                handleDaySlotChange(dayBox);
              }}
            >
              <div className="day-box__day">{day}</div>
              <div className="day-box__date">{date}</div>
              <div>{timesSlots?.includes("10:00-22:00") && timesSlots}</div>
            </div>
          );
        }
      })}
    </div>
  );

  return (
    <div className="day-box">
      <DayBox />
    </div>
  );
}
