import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles({
  root: {
    "& label.Mui-focused": {
      color: (props) => (props.addressError ? "red" : "#2e624f"),
    },
    "& div.MuiFilledInput-root:after": {
      borderBottom: (props) =>
        props.addressError ? "2px solid red" : "2px solid #2e624f",
    },
  },
});

export default function TextInput({ setAddress, addressError, ...rest }) {
  const { t } = useTranslation();

  const inputProps = { ...rest };
  const classes = useStyles({ addressError });

  return (
    <TextField
      label={t("component.text_input.label")}
      variant="filled"
      fullWidth
      {...rest}
      className={classes.root}
      InputProps={{
        endAdornment: inputProps.value && (
          <ClearIcon
            onClick={() => setAddress("")}
            style={{ cursor: "pointer", paddingTop: "5px" }}
          />
        ),
      }}
    />
  );
}
