/* eslint-disable dot-notation */
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DaySelection from "../DaySelection";
import SlotSelection from "../SlotSelection";

import {
  getListOfDays,
  zapietLocationLad,
  getCookie,
  getIpAddress,
  getZipcodeWithreverseGeocodeByAddress,
  getURLParams,
  getZipcodeInfo,
} from "../../utils/helpers";
import SubmitButton from "../SubmitButton";
import { BasketContext } from "../../context/BasketContext";

const days = getListOfDays(0, 7);

export default function LocalDelivery({ address }) {
  const { t } = useTranslation();

  const [selectedDeliveryDay, setSelectedDeliveryDay] = useState(null);
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState(null);
  const [isCartUpdated, setIsCartUpdated] = useState(null);
  const [userZipcode, setUserZipcode] = useState(() => {
    localStorage.getItem("zipCode");
  });
  const [city, setCity] = useState("");
  const { setBasket } = useContext(BasketContext);

  const location = useLocation();

  useEffect(() => {
    if (!userZipcode) {
      // get zipcode from google maps
      const urlParamsJSON = getURLParams(location);
      const coordinates = {
        lat: urlParamsJSON["lat"] ? parseFloat(urlParamsJSON["lat"]) : 48.8566,
        lng: urlParamsJSON["lng"] ? parseFloat(urlParamsJSON["lng"]) : 2.3522,
      };
      getZipcodeWithreverseGeocodeByAddress(coordinates).then(
        async (zipcode) => {
          const zipcodeInfo = await getZipcodeInfo(zipcode);

          if (zipcodeInfo?.city) {
            setCity(zipcodeInfo?.city);
            setUserZipcode(zipcodeInfo);
          }
        }
      );
    }
  }, []);

  const handleTimeSlotChange = async (event) => {
    const {
      value,
      dataset: { deliveryTimeSlot },
    } = event.target;
    setIsCartUpdated(null);
    setSelectedDeliveryTime(value);
    const _ZapietId = `M=D&L=${zapietLocationLad}`;
    const attributes = {
      "Checkout-Method": "Livraison à domicile",
      "Delivery-Location-Id": zapietLocationLad,
      "Delivery-Date": `${selectedDeliveryDay?.value?.day} ${selectedDeliveryDay?.value?.date}`,
      "Delivery-Time": value,
      anonymousId: JSON.parse(localStorage.getItem("ajs_anonymous_id")),
      fbp: getCookie("_fbp"),
      fbc: getCookie("_fbc"),
      useragent: navigator.userAgent,
      "Pickup-Location-Id": "",
      "Pickup-Location-Company": "",
      "Pickup-Location-Address-Line-1": "",
      "Pickup-Location-Address-Line-2": "",
      "Pickup-Location-Region": "",
      "Pickup-Location-Country": "",
      "Delivery-Date-&-Time": "",

      // The followging attributes are used in FaitMaison checkout
      "Delivery-Day": selectedDeliveryDay?.deliveryDay, // 0-6 (sunday - saturday)
      "First-Shipping-Date": selectedDeliveryDay?.firstShippingDate, // 2021-12-20T00:00:00Z
      "Delivery-Time-Slot": deliveryTimeSlot, // 18:00-20:00
      "Pickup-Location-Postal-Code": userZipcode?.zipcode,
      "Pickup-Location-City": city,
    };

    setBasket(
      (prevState) => ({
        ...prevState,
        attributes,
        _ZapietId,
        _FaitMaisonId: "",
      }),
      setIsCartUpdated(true)
    );
  };

  return (
    <div className="ld">
      <h1 className="ld__header">{t("component.local_delivery.header")}</h1>
      <DaySelection
        selectedDeliveryDay={selectedDeliveryDay}
        setSelectedDeliveryDay={setSelectedDeliveryDay}
        setIsCartUpdated={setIsCartUpdated}
        days={days}
        userZipcode={userZipcode}
      />
      <div className="ld__date-selection">
        <SlotSelection
          selectedDeliveryDay={selectedDeliveryDay}
          value={selectedDeliveryTime}
          onChange={handleTimeSlotChange}
          userZipcode={userZipcode}
        />
      </div>
      <div className="ld__submit-btn">
        <SubmitButton
          disabled={!isCartUpdated || !selectedDeliveryTime}
          onClick={() => {
            //  Analytics
            window.rudderanalytics.track("Checkout Step Completed", {
              postal_code: userZipcode,
              step: "2",
            });
          }}
          data={isCartUpdated}
        />
      </div>
    </div>
  );
}
