/* eslint-disable no-undef */
/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { defaultBounds } from "../../constants/utils";
import TextInput from "../TextInput";
import AddressSuggestions from "../AddressSuggestions";
import {
  getZipcodeWithreverseGeocodeByAddress,
  getZipcodeInfo,
} from "../../utils/helpers";
import { country } from "../../utils/i18n";

const searchOptions = {
  componentRestrictions: { country },
  bounds: defaultBounds,
  types: ["geocode"],
  strictBounds: true,
};

export default function AddressAutocomplete({
  address,
  setAddress,
  addressError,
  setAddressError,
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const buttonClassNames = clsx(
    "a-autocomplete__icon",
    (addressError || !address || loading) && "a-autocomplete__icon--disabled"
  );

  const handleChange = (newAddress) => {
    setAddressError("");
    setAddress(newAddress);
  };
  const handleSelect = (selectedAddress) => {
    setLoading(true);
    setAddress(selectedAddress);
    geocodeByAddress(
      country === "es" && selectedAddress === "08001"
        ? `${selectedAddress} Barcelone`
        : selectedAddress
    )
      .then((results) => {
        const countryCode = country.toUpperCase();
        const filteredResults = results.filter((result) => {
          return result.address_components.some((component) => {
            return (
              component.types.includes("country") &&
              component.short_name === countryCode
            );
          });
        });
        const formattedAddresses = results.map((a) => a.formatted_address);
        window.rudderanalytics.track("Store Locator Address Entered", {
          selected_address: selectedAddress,
          input_address: address,
          results: formattedAddresses,
          selected_results: filteredResults,
        });

        if (filteredResults.length > 0) {
          return getLatLng(filteredResults[0]);
        } else {
          throw new Error(
            "No geocoding results found for the specified country."
          );
        }
      })
      .then((latLng) => {
        const { lat, lng } = latLng;

        getZipcodeWithreverseGeocodeByAddress({ lat, lng }).then(
          async (zipcode) => {
            if (zipcode) {
              // Analytics

              window.rudderanalytics.track("Checkout Step Completed", {
                postal_code: zipcode,
                step: "1",
              });

              const enteredZipcodeDetails = await getZipcodeInfo(zipcode);

              if (enteredZipcodeDetails) {
                localStorage.setItem("zipCode", enteredZipcodeDetails?.zipcode);
                localStorage.setItem("hubId", enteredZipcodeDetails?.hub_id);
                navigate({
                  pathname: "/",
                  search: `activeStep=deliveryMethod&address=${selectedAddress}&lat=${lat}&lng=${lng}`,
                });
              } else {
                setAddressError("unavailable");
                setLoading(false);
              }
            } else {
              setAddressError("unavailable");
              setLoading(false);
            }
          }
        );
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error", error);
      });
  };

  return (
    <div className="a-autocomplete">
      <PlacesAutocomplete
        value={address}
        searchOptions={searchOptions}
        onChange={handleChange}
        onSelect={handleSelect}
        onError={() => {
          setAddressError("not-found");
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, _ }) => (
          <div className="a-autocomplete__container">
            <TextInput
              {...getInputProps({
                className: "location-search-input",
              })}
              setAddress={setAddress}
              addressError={addressError}
            />

            {!addressError ? (
              <AddressSuggestions
                suggestions={suggestions}
                getSuggestionItemProps={getSuggestionItemProps}
              />
            ) : (
              <div className="a-autocomplete__error">
                {addressError === "not-found" &&
                  t("component.address_autocomplete.error_no_address_found")}
                {addressError === "unavailable" &&
                  t(
                    "component.address_autocomplete.error_address_not_delivered_yet"
                  )}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>

      <button
        className={buttonClassNames}
        disabled={addressError || !address || loading}
        onClick={() => handleSelect(address)}
        type="button"
      >
        {loading ? (
          <CircularProgress
            color="inherit"
            style={{ height: "35x", width: "35px" }}
          />
        ) : (
          <ArrowForwardIcon fontSize="large" />
        )}
      </button>
    </div>
  );
}
