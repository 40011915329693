import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { formatAvailableSlots } from "../../utils/helpers";

export default function SlotSelection({
  value,
  onChange,
  userZipcode,
  availableSlots,
  selectedDeliveryDay,
}) {
  const handleChange = async (event) => {
    await onChange(event);
  };

  let content = null;
  if (userZipcode) {
    // We are in LAD
    content = userZipcode?.home_delivery[selectedDeliveryDay?.id]?.map(
      (slot) => {
        const slotWithValueAndLabel = formatAvailableSlots(slot);

        if (slot === "10:00-22:00") {
          return (
            <p>
              Vous recevrez une notification le jour de votre livraison, avec
              l'horaire de passage
            </p>
          );
        }
        return (
          <FormControlLabel
            value={slotWithValueAndLabel.value}
            control={
              <Radio
                // THIS CHECK IS ONLY FOR JEUDI DE L'ASCENSION, TO REMOVE DIMANCHE 22/05

                sx={{
                  "&.Mui-checked": {
                    color: "#fc7033",
                  },
                }}
                inputProps={{
                  "data-delivery-day": slot?.deliveryDay,
                  "data-first-shipping-date": slot?.firstShippingDate,
                  "data-delivery-time-slot":
                    slotWithValueAndLabel?.deliveryTimeSlot,
                }}
              />
            }
            label={slotWithValueAndLabel.label}
          />
        );
      }
    );
  } else if (availableSlots) {
    content = availableSlots.map((slot) => (
      <FormControlLabel
        value={slot.value}
        control={
          <Radio
            sx={{
              "&.Mui-checked": {
                color: "#fc7033",
              },
            }}
            inputProps={{
              "data-delivery-day": slot?.deliveryDay,
              "data-first-shipping-date": slot?.firstShippingDate,
              "data-delivery-time-slot": slot?.deliveryTimeSlot,
            }}
          />
        }
        label={slot.label}
      />
    ));
  }
  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="slot-selection"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {content}
      </RadioGroup>
    </FormControl>
  );
}
