import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import AddressAutocomplete from "../AddressAutocomplete/AddressAutocomplete";
import { country } from "../../utils/i18n";

const useStyles = makeStyles({
  root: {
    "&.MuiButton-root": {
      color: "#339760",
      border: "1px solid rgba(51, 151, 96, 0.5);",
      marginTop: "20px",
      textTransform: "lowercase",
      "&:hover": {
        border: "1px solid rgba(51, 151, 96, 0.5);",
      },
    },
  },
});
// Add comment in address Selection

const AddressSelection = ({ address, setAddress }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const [addressError, setAddressError] = useState("");
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({
      pathname: "/",
      search: "activeStep=partners",
    });
  };

  useEffect(() => {
    // Analytics
    window.rudderanalytics.page("Delivery Selector - Address");
  }, []);

  return (
    <div className="address-selection">
      <h1 className="address-selection__header address-selection__header--mobile">
        {t("component.address_selection.mobile_header")}
      </h1>
      <h1 className="address-selection__header address-selection__header--desktop">
        {t("component.address_selection.desktop_header")}
      </h1>
      <AddressAutocomplete
        address={address}
        setAddress={setAddress}
        addressError={addressError}
        setAddressError={setAddressError}
      />
      <Button
        type="button"
        variant="outlined"
        className={classes.root}
        onClick={handleClick}
      >
        <span className="address-selection__partner-text">
          {t("component.address_selection.partner_text")}
        </span>
        <ArrowForwardIcon
          className="address-selection__partner-icon"
          fontSize="small"
        />
      </Button>
    </div>
  );
};

export default AddressSelection;
