/* eslint-disable no-nested-ternary */
const env = process.env.REACT_APP_NODE_ENV;
const baseURL =
  env === "production"
    ? "https://api.horsnorm.es"
    : env === "staging"
    ? "https://api-staging.horsnorm.es"
    : "https://api-dev.horsnorm.es";
const bucketURL =
  "https://horsnormes-translation.s3.eu-west-3.amazonaws.com/store-locator";

export { baseURL, env, bucketURL };
