/* eslint-disable react/self-closing-comp */
import React from "react";

function AddressSugestionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <circle
        cx="10.5"
        cy="10.636"
        r="10.364"
        fill="#339760"
        fillOpacity="0.5"
      ></circle>
      <path
        fill="#2E624F"
        d="M10.5 3.583a5.538 5.538 0 00-5.542 5.542c0 4.156 5.542 10.292 5.542 10.292s5.542-6.136 5.542-10.292A5.538 5.538 0 0010.5 3.583zm0 7.521a1.98 1.98 0 11.001-3.96 1.98 1.98 0 01-.001 3.96z"
      ></path>
    </svg>
  );
}

export default AddressSugestionIcon;
